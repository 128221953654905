import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthTokenInitialState, AuthTokenTypes } from './authToken.state.ts';
import { verifyToken } from 'store/feature/authToken/authToken.thunk.ts';
import { QueryState, SitePermission } from '@/common/enum';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
import { Nullable } from 'interfaces/Nullable.ts';

const initialState: AuthTokenTypes = AuthTokenInitialState;

// noinspection JSUnusedGlobalSymbols
export const authTokenSlice = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    SET_AUTH_TOKEN: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    SET_ERROR_MSG: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    SET_APP_LOADING: (state, action: PayloadAction<boolean>) => {
      state.isAppLoading = action.payload;
    },
    SET_STATION_NO: (state, action: PayloadAction<number>) => {
      state.stationId = action.payload;
    },
    SET_INITIAL_STATE: (
      state,
      action: PayloadAction<{
        userAuth: Partial<IUserPortal>;
        userId: Nullable<number>;
        sitePermission: Nullable<SitePermission>;
      }>
    ) => {
      state.tokenVerification = action.payload.userAuth;
      state.userId = action.payload.userId;
      state.sitePermission = action.payload.sitePermission;
      state.isAppLoading = false;
      state.queryState = QueryState.READY;
    },
    RESET_AUTH_TOKEN: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(verifyToken.pending, (state) => {
        state.token = null;
        state.tokenVerification = null;
        state.queryState = QueryState.AWAIT;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        // state.queryState = QueryState.READY;
        if (action.payload) state.tokenVerification = action.payload;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default authTokenSlice.reducer;
