import { Nullable } from 'interfaces/Nullable.ts';
import { QueryState, SitePermission } from '@/common/enum';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

export type AuthTokenTypes = {
  queryState: QueryState;
  token: Nullable<string>;
  tokenVerification: Nullable<Partial<IUserPortal>>;
  userId: Nullable<number>;
  sitePermission: Nullable<SitePermission>;
  error: Nullable<string>;
  isAppLoading: boolean;
  stationId: Nullable<number>;
};

export const AuthTokenInitialState: AuthTokenTypes = {
  queryState: QueryState.UNDEFINED,
  token: null,
  tokenVerification: null,
  userId: null,
  sitePermission: null,
  error: null,
  isAppLoading: false,
  stationId: null,
};
