import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { GenderInfo, ScannerModels } from '@/common/enum';

export const Stations = [
  { title: 'station 1 - Vehicle Monitoring and Decontamination', value: '1' },
  { title: 'station 2 - Evacuee Monitoring', value: '2' },
  { title: 'station 3 - Evacuee Decontamination', value: '3' },
  { title: 'station 4 - KI Distribution', value: '4' },
  { title: 'station 5 - Registration', value: '5' },
];

export const StationTitle = [
  { title: 'Station 1', description: 'Vehicle Monitoring' },
  { title: 'Station 2', description: 'Evacuee Monitoring' },
  { title: 'Station 3', description: 'Evacuee Monitoring and Decontamination' },
  { title: 'Station 4', description: 'KI Distribution' },
  { title: 'Station 5', description: 'Registration' },
];

export const withContamination = 299;

export const DECON_SELECTED_INCIDENT_SETTING_TYPE = 'deconSelectedIncident';

export const ADMIN_USERS_TEMP = [11399, 9847, 10383, 115, 4883, 907, 3344, 9006, 784];

export const scannerOptions: RadioSelectOptions[] = [
  {
    value: ScannerModels.Model52,
    label: 'Model 52',
  },
  { value: ScannerModels.Model521, label: 'Model 52-1' },
];

export const designationOptions: RadioSelectOptions[] = [
  { value: GenderInfo.Female, label: 'Female queue' },
  { value: GenderInfo.Male, label: 'Male queue' },
];
