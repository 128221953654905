import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { array, number, object, string, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { IStationTwoForm } from 'interfaces/StationForms.ts';
import { GenderInfo } from '@/common/enum';

export const defaultValues: IStationTwoForm = {
  id: 0,
  activeIdx: null,
  driverLicense: '',
  firstName: '',
  lastName: '',
  dob: null,
  ssn: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  gender: GenderInfo.Male,
  contaminationStatus: null,
  wristBand: null,
  wristBandCode: null,
  model52: {
    L3: false,
    R3: false,
    L2: false,
    R2: false,
    L1: false,
    R1: false,
    Head: false,
    Feet: false,
    operator: '',
    scribe: '',
    scannerSerial: '',
  },
  model521: {
    LT: false,
    RT: false,
    LB: false,
    RB: false,
    operator: '',
    scribe: '',
    scannerSerial: '',
  },
  meterScreening: {
    hair: 0,
    head: 0,
    neck: 0,
    shoulderL: 0,
    shoulderR: 0,
    armL: 0,
    armR: 0,
    handL: 0,
    handR: 0,
    chest: 0,
    back: 0,
    legL: 0,
    legR: 0,
    footL: 0,
    footR: 0,
    operator: '',
    scribe: '',
    scannerNo: '',
    probeSerial: '',
  },
  screenings: [],
  members: [],
  postInfo: {
    index: 0,
    post: '',
  },
};

function useStationTwoForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        memberId: number().required('Member ID is required').min(1, 'Not a valid member ID'),
        stationState: number(),
        contaminationStatus: string(),
        wristBand: string(),
        wristBandCode: string().nullable(),
        model52: object().shape({
          L3: boolean(),
          R3: boolean(),
          L2: boolean(),
          R2: boolean(),
          L1: boolean(),
          R1: boolean(),
          Head: boolean(),
          Feet: boolean(),
        }),
        model521: object().shape({
          LT: boolean(),
          RT: boolean(),
          LB: boolean(),
          RB: boolean(),
        }),
        screenings: array()
          .of(
            object().shape({
              screeningType: number(),
            })
          )
          .min(1, 'Must have at least one screening'),
      }),
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({ defaultValues, resolver: yupResolver(validationSchema) });
}

export default useStationTwoForm;
