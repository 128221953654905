import { StationInitialState, StationStateTypes } from './stationFive.state.ts';
import { createSlice } from '@reduxjs/toolkit';
import { updateStationFive } from 'store/feature/stationFive/stationFive.thunk.ts';
import { QueryState } from '@/common/enum';

const initialState: StationStateTypes = StationInitialState;

export const stationFiveSlice = createSlice({
  name: 'stationFive',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateStationFive.pending, (state) => {
      state.queryState = QueryState.AWAIT;
      state.error = null;
    });
    builder.addCase(updateStationFive.fulfilled, (state) => {
      state.queryState = QueryState.UPDATED;
    });
    builder.addCase(updateStationFive.rejected, (state, action) => {
      state.queryState = QueryState.AWAIT;
      state.error = action.error.message ?? '';
    });
  },
});

export default stationFiveSlice.reducer;
