import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { ActiveProcessed, AuthProtect, GenderInfo } from '@/common/enum';

export const fetchByStation = (params: {
  incidentId: string;
  receptionId: string;
  stationId: number;
  status: ActiveProcessed;
  page: string;
  limit: string;
  filterString?: string;
  gender?: GenderInfo;
}): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/station-que-list',
  params,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateStation = (stationId: number, data: any): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: `/api/station${stationId}`,
  data,
});

export const fetchHouseholdMembers = (params: { search: string }): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/household-member',
  params,
});
