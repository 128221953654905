import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import {
  selectAuthToken,
  appLoadingSelector,
  selectAuthTokenError,
  selectAuthTokenQueryState,
  selectAuthTokenVerification,
} from 'store/feature/authToken/authToken.selector.ts';
import { SitePermission, RoutePaths, QueryState } from '@/common/enum';
import { verifyToken } from 'store/feature/authToken/authToken.thunk';
import {
  SET_APP_LOADING,
  SET_INITIAL_STATE,
  SET_AUTH_TOKEN,
} from 'store/feature/authToken/authToken.action';
import { SET_ACCOUNT } from 'store/feature/accountInfo/account.actions';
import { getPermissionsForSite } from '@/utils';
import { ErrorMessage, Loading } from 'components/primitive';

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idToken = searchParams.get('id_token');
  const urlPath = location.pathname;
  const dispatch = useAppDispatch();
  const authTokenVerification = useAppSelector(selectAuthTokenVerification);
  const authTokenError = useAppSelector(selectAuthTokenError);
  const authToken = useAppSelector(selectAuthToken);
  const isLoading = useAppSelector(appLoadingSelector);
  const isError = useAppSelector(selectAuthTokenQueryState) === QueryState.FAIL;
  const isReady = useAppSelector(selectAuthTokenQueryState) === QueryState.READY;

  useEffect(() => {
    if (idToken) {
      dispatch(verifyToken(idToken));
      dispatch(SET_AUTH_TOKEN(idToken));
      dispatch(SET_APP_LOADING(true));
    }
  }, [dispatch, idToken]);

  useEffect(() => {
    if (!authTokenVerification && !isReady) {
      if (urlPath === '/') return navigate('/login');
      if (urlPath.includes('station')) return navigate('/login');
      if (urlPath.includes('admin')) return navigate('/login');
      if (urlPath.includes('login-setup')) return navigate('/login');
    } else if (authTokenVerification && authToken) {
      if (urlPath.includes('admin')) {
        navigate(RoutePaths.ADMIN);
        return;
      }
      if (urlPath.includes('station1')) {
        navigate(RoutePaths.STATION1);
        return;
      }
      if (urlPath.includes('station2')) {
        navigate(RoutePaths.STATION2);
        return;
      }
      if (urlPath.includes('station3')) {
        navigate(RoutePaths.STATION3);
        return;
      }
      if (urlPath.includes('station4')) {
        navigate(RoutePaths.STATION4);
        return;
      }
      if (urlPath.includes('station5')) {
        navigate(RoutePaths.STATION5);
        return;
      }

      let userPermission = SitePermission.U;
      const sitePermission = getPermissionsForSite(
        'DECON',
        authTokenVerification?.pvActiveGroupID ?? 0,
        authTokenVerification?.attributes ?? []
      );
      const permissions = sitePermission.sitePermissions;
      // *: Permission Type
      switch (permissions && permissions[0]) {
        case 'A':
          userPermission = SitePermission.A;
          break;
        case 'U':
          userPermission = SitePermission.U;
          break;
        default:
          userPermission = SitePermission.U;
      }

      const payload = {
        userAuth: authTokenVerification,
        userId: authTokenVerification.id ?? 0,
        sitePermission: userPermission,
      };
      dispatch(SET_INITIAL_STATE(payload));
      dispatch(SET_ACCOUNT(authTokenVerification));
      navigate(RoutePaths.LOGIN_SETUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTokenVerification, isReady, navigate, urlPath]);

  if (isLoading) {
    return <Loading message={'Authentication in process, please wait...'} />;
  }

  if (isError) {
    return <ErrorMessage message={authTokenError ?? 'Failed to validate user token.'} />;
  }

  return <Outlet />;
};

export default AuthPage;
