import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  Button,
  ETIDialog,
  ETIDialogHeader,
  ETIDialogBody,
  ETIDialogFooter,
  IconButton,
  FormContentOption,
  Select,
  Typo,
} from '@zawarski/eti-typescript-components';
import { Grid, CircularProgress, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import useStyles from './ModalOptions.styles';
import { ILoginForm } from 'hooks/useLoginForm';
import { CreatePostOne, CreatePostThree, CreatePostTwo } from 'components/fragment';
import { YesNo } from '@/common/enum';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { Stations } from '@/common/static';
import { convertToTitleCase } from '@/utils';

type ModalOptionsViewProps = {
  processing: boolean;
  form: UseFormReturn<ILoginForm>;
  onConfirm: () => void;
  goToAdmin: () => void;
  cbCancel?: () => void;
};

const ModalOptionsView: React.FC<ModalOptionsViewProps> = ({
  onConfirm,
  goToAdmin,
  form,
  processing,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isOpen = form.watch('isOpen');
  const isAdmin = form.watch('isAdmin');
  const receptionCenterOptions = form.watch('receptionCentersOptions');
  const postOptions = form.watch('postsOptions');
  const stationOptions = form.watch('stationOptions');
  const receptionCenterID = form.watch('receptionCenterID');
  const receptions = form.watch('receptions');
  const showStationMenu = form.watch('showStationMenu');
  const showPostMenu = form.watch('showPostMenu');
  const selectedPost = form.watch('selectedPost');
  const stationValue = form.watch('station');
  const errorMsg = form.watch('errorMsg');
  const hasError = errorMsg && errorMsg.length > 0;
  const noPost = stationValue === '4' || stationValue === '5';

  const activeReception = useMemo(() => {
    return receptions.find((reception) => reception.pvDataID === receptionCenterID);
  }, [receptionCenterID, receptions]);
  const activeRCIndex = useMemo(() => {
    let activeIndex = 0;
    receptions.forEach((reception, index) => {
      if (reception.pvDataID === receptionCenterID) {
        activeIndex = index;
      }
    });
    return activeIndex;
  }, [receptionCenterID, receptions]);

  const lastIndexOfPost = useMemo(() => {
    let lastIndex = 0;
    if (activeReception) {
      if (stationValue === '1') lastIndex = activeReception.settingsS1.lastIndexOfPost;
      if (stationValue === '2') lastIndex = activeReception.settingsS2.lastIndexOfPost;
      if (stationValue === '3') lastIndex = activeReception.settingsS3.lastIndexOfPost;
    }
    return lastIndex;
  }, [activeReception, stationValue]);

  const isCombo = useMemo(() => {
    if (activeReception) {
      return activeReception.settingsS3.isOperatorScribeCombo === YesNo.Yes;
    }
    return false;
  }, [activeReception]);

  const hasStationOne = activeReception?.settingsS1.isActive === YesNo.Yes;
  const disableConfirm = useMemo(() => {
    if (activeReception) {
      if (stationValue === '1') {
        return selectedPost === null || selectedPost === 'empty';
      }
      if (stationValue === '2') {
        return selectedPost === null || selectedPost === 'empty';
      }
      if (stationValue === '3') {
        return selectedPost === null || selectedPost === 'empty';
      }
      if (stationValue === '4' || stationValue === '5') {
        return false;
      }
    }
    return true;
  }, [stationValue, selectedPost, activeReception]);

  const stationOptionsDefault: RadioSelectOptions[] = useMemo(
    () => Stations.map((item) => ({ label: convertToTitleCase(item.title), value: item.value })),
    []
  );

  useEffect(() => {
    if (receptionCenterID && activeReception) {
      if (!hasStationOne) {
        const newStationOptions = stationOptionsDefault.filter((station) => station.value !== '1');
        form.setValue('stationOptions', newStationOptions);
        form.setValue('showStationMenu', true);
      } else {
        form.setValue('stationOptions', stationOptionsDefault);
        form.setValue('showStationMenu', true);
      }
      form.setValue('showPostMenu', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receptionCenterID, activeReception, hasStationOne]);

  useEffect(() => {
    if (receptionCenterID && activeReception && stationValue) {
      if (stationValue === '1' && hasStationOne) {
        // *: Get available posts for Station 1
        const posts = activeReception.settingsS1.availablePosts;
        const tempPostOptions: RadioSelectOptions[] = [{ label: 'Create post', value: 'create' }];
        if (posts && posts.length && posts.some((post) => post.posts && post.posts.length)) {
          posts.forEach((post) => {
            tempPostOptions.push({
              label: post.posts ?? '',
              value: String(post.index) ?? 'empty',
            });
          });
        }
        form.setValue('postsOptions', tempPostOptions);
        return form.setValue('showPostMenu', true);
      }
      if (stationValue === '2') {
        // *: Get available posts for Station 2
        const posts = activeReception.settingsS2.availablePosts;
        const tempPostOptions: RadioSelectOptions[] = [{ label: 'Create post', value: 'create' }];
        if (posts && posts.length && posts.some((post) => post.posts && post.posts.length)) {
          posts.forEach((post) => {
            tempPostOptions.push({
              label: post.posts ?? '',
              value: String(post.index) ?? 'empty',
            });
          });
        }
        form.setValue('postsOptions', tempPostOptions);
        return form.setValue('showPostMenu', true);
      }
      if (stationValue === '3') {
        const posts = activeReception.settingsS3.availablePosts;
        const tempPostOptions: RadioSelectOptions[] = [{ label: 'Create post', value: 'create' }];
        if (posts && posts.length && posts.some((post) => post.posts && post.posts.length)) {
          posts.forEach((post) => {
            tempPostOptions.push({
              label: post.posts ?? '',
              value: String(post.index) ?? 'empty',
            });
          });
        }
        form.setValue('postsOptions', tempPostOptions);
        return form.setValue('showPostMenu', true);
      }
    }
  }, [form, hasStationOne, stationValue, receptionCenterID, activeReception]);

  const onClose = () => {
    if (props.cbCancel) {
      props.cbCancel();
    }
    form.setValue('isOpen', false);
  };

  return (
    <ETIDialog open={isOpen} maxWidth={'md'}>
      <ETIDialogHeader
        title={'SELECT OPTIONS'}
        titleVariant={'h6'}
        titleColor={'primary'}
        subtitle={'Choose a reception center and designated station or setup a new station.'}
        subtitleVariant={'body2'}
        subtitleColor={'#707070'}
        closeButton={
          <IconButton
            color={'secondary'}
            size={'small'}
            onClick={onClose}
            children={<CloseIcon sx={{ color: '#707070' }} />}
          />
        }
      />
      <ETIDialogBody
        dividers={true}
        sx={{ padding: '8px' }}
        children={[
          <Box key={'1'} sx={classes.content}>
            {!!isAdmin && (
              <Button color={'primary'} fullWidth onClick={goToAdmin}>
                LOGIN AS ADMIN
              </Button>
            )}
            <FormContentOption title='STATION SELECTION'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={form.control}
                    name='receptionCenterID'
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Select
                        required
                        size={'small'}
                        color={'primary'}
                        selectOptions={receptionCenterOptions}
                        label='Reception center'
                        error={Boolean(error)}
                        helperText={error ? error.message : ''}
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
                {showStationMenu && (
                  <Grid item xs={12}>
                    <Controller
                      control={form.control}
                      name='station'
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Select
                          required
                          size={'small'}
                          color={'primary'}
                          selectOptions={stationOptions}
                          label='Station'
                          error={Boolean(error)}
                          helperText={error ? error.message : ''}
                          value={String(value)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                )}
                {!noPost && showPostMenu && (
                  <Grid item xs={12}>
                    <Controller
                      control={form.control}
                      name='selectedPost'
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <Select
                          required
                          size={'small'}
                          color={'primary'}
                          selectOptions={postOptions}
                          label='Post'
                          error={Boolean(error)}
                          helperText={error ? error.message : ''}
                          value={String(value)}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </FormContentOption>
            {selectedPost === 'create' && stationValue === '1' ? (
              <CreatePostOne
                activeIndex={activeRCIndex}
                lastIndexOfPost={lastIndexOfPost}
                form={form}
              />
            ) : null}
            {selectedPost === 'create' && stationValue === '2' ? (
              <CreatePostTwo
                activeIndex={activeRCIndex}
                form={form}
                lastIndexOfPost={lastIndexOfPost}
              />
            ) : null}
            {selectedPost === 'create' && stationValue === '3' ? (
              <CreatePostThree
                activeIndex={activeRCIndex}
                form={form}
                lastIndexOfPost={lastIndexOfPost}
                isCombo={isCombo}
              />
            ) : null}
            {hasError && (
              <Box sx={classes.errorWrapper}>
                <Typo sx={classes.error}>{errorMsg}</Typo>
              </Box>
            )}
          </Box>,
        ]}
      />
      <ETIDialogFooter>
        <Button color={'secondary'} disabled={processing} onClick={onClose}>
          CANCEL
        </Button>
        <Button
          color={'primary'}
          endIcon={processing ? <CircularProgress size={20} /> : null}
          disabled={disableConfirm}
          onClick={onConfirm}>
          CONFIRM
        </Button>
      </ETIDialogFooter>
    </ETIDialog>
  );
};

export default ModalOptionsView;
