import { RootState } from '@/store';

export const selectAuthTokenVerification = (state: RootState) => state.authToken.tokenVerification;
export const selectAuthTokenQueryState = (state: RootState) => state.authToken.queryState;
export const selectAuthTokenError = (state: RootState) => state.authToken.error;
export const selectAuthToken = (state: RootState) => state.authToken.token;
export const userIDSelector = (state: RootState) => state.authToken.userId;
export const appLoadingSelector = (state: RootState) => state.authToken.isAppLoading;
export const stationIdSelector = (state: RootState) => state.authToken.stationId;
export const sitePermissionSelector = (state: RootState) => state.authToken.sitePermission;
