// noinspection JSUnusedGlobalSymbols

import { authTokenSlice } from './authToken.slice.ts';

export const {
  SET_AUTH_TOKEN,
  SET_STATION_NO,
  SET_ERROR_MSG,
  RESET_AUTH_TOKEN,
  SET_INITIAL_STATE,
  SET_APP_LOADING,
} = authTokenSlice.actions;
