import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '8px',
    maxHeight: '650px',
    minWidth: '600px',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    borderRadius: '8px',
    bgcolor: '#F0F0F0',
    border: '1px solid #E0E0E0',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      minHeight: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    alignItems: 'center',
    borderRadius: '8px',
    gap: '16px',
    padding: '24px',
    bgcolor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.grey[100]}`,
  },
  errorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '8px',
    borderRadius: '3px',
    bgcolor: '#FFF1F4',
    boxShadow: '0px 0.25px 1px 0px rgba(0, 0, 0, 0.04), 0px 0.85px 3px 0px rgba(0, 0, 0, 0.19)',
  },
  error: {
    fontSize: '0.75rem',
    color: theme.palette.error.main,
    fontWeight: 700,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
});

export default useStyles;
