import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ILoginForm } from 'hooks/useLoginForm';
import {
  FormContentOption,
  FormGroup,
  RadioButtonsGroup,
  TextField,
} from '@zawarski/eti-typescript-components';
import { Grid } from '@mui/material';
import { YesNo } from '@/common/enum';

type CreatePostViewProps = {
  activeIndex: number;
  lastIndexOfPost: number;
  form: UseFormReturn<ILoginForm>;
};

const CreatePostOneView: React.FC<CreatePostViewProps> = ({
  activeIndex,
  lastIndexOfPost,
  form,
}) => {
  return (
    <FormContentOption title='POST OPTIONS'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={form.control}
            name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.posts`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={Boolean(error)}
                helperText={error?.message ?? ''}
                label='Posts'
                value={value ?? ''}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={form.control}
            name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.operator`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={Boolean(error)}
                helperText={error?.message ?? ''}
                label='Operator'
                value={value ?? ''}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={form.control}
            name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.scribe`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={Boolean(error)}
                helperText={error?.message ?? ''}
                label='Scribe'
                value={value ?? ''}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={form.control}
            name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.scannerNo`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={Boolean(error)}
                helperText={error?.message ?? ''}
                label='Scanner number'
                value={value ?? ''}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={form.control}
            name={`receptions.${activeIndex}.settingsS1.availablePosts.${lastIndexOfPost}.probeSerial`}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                fullWidth
                error={Boolean(error)}
                helperText={error?.message ?? ''}
                label='Probe serial'
                value={value ?? ''}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormContentOption>
  );
};

export default CreatePostOneView;
