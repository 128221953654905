import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch from '@/services/apiFetch.ts';
import { StationFourPayload, UpdateResponse } from 'interfaces/station.interface.ts';
import { RootState } from '@/store';
import { updateStation } from '@/services/station.endpoint.ts';

export const updateStationFour = createAsyncThunk(
  'STATION_FOUR/UPDATE',
  async (payload: StationFourPayload, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const stationId = store.authToken.stationId;
      if (!stationId) return Promise.reject('Station ID not found');
      const endpoint = updateStation(stationId, payload);
      return await apiFetch<UpdateResponse>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      console.log(error);
      let message = 'Something went wrong!';
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
