import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import AuthPage from '@/auth';
import { MainLayout } from 'components/layout';

// *: LOADER FUNCTIONS & CONTAINERS
import SelfRegisterLoaderContainer, {
  loaderSelfRegister,
} from 'pages/SelfRegister/SelfRegister.loader.tsx';

// *: COMPONENTS PAGE
const AdminPage = lazy(() => import('pages/AdminPage'));
const LoginPage = lazy(() => import('pages/Login'));
const StationOnePage = lazy(() => import('pages/StationOne'));
const StationTwoPage = lazy(() => import('pages/StationTwo'));
const StationThreePage = lazy(() => import('pages/StationThree'));
const Station5Page = lazy(() => import('pages/Station5'));
const Station4Page = lazy(() => import('pages/Station4'));
const SelfRegisterPage = lazy(() => import('pages/SelfRegister'));
const AutoLoginPage = lazy(() => import('pages/AutoLogin'));

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AuthPage />}>
      <Route
        path='login'
        element={
          <Suspense fallback={<LinearProgress />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path='login-setup'
        element={
          <Suspense fallback={<LinearProgress />}>
            <AutoLoginPage />
          </Suspense>
        }
      />
      <Route path='admin' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <AdminPage newEntry={false} />
            </Suspense>
          }
        />
        <Route
          path='new'
          element={
            <Suspense fallback={<LinearProgress />}>
              <AdminPage newEntry={true} />
            </Suspense>
          }
        />
      </Route>
      <Route path='station1' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <StationOnePage />
            </Suspense>
          }
        />
      </Route>
      <Route path='station2' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <StationTwoPage />
            </Suspense>
          }
        />
      </Route>
      <Route path='station3' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <StationThreePage />
            </Suspense>
          }
        />
      </Route>
      <Route path='station4' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <Station4Page />
            </Suspense>
          }
        />
      </Route>
      <Route path='station5' element={<MainLayout />}>
        <Route
          index
          element={
            <Suspense fallback={<LinearProgress />}>
              <Station5Page />
            </Suspense>
          }
        />
      </Route>
      <Route
        path='self-register'
        element={
          <Suspense fallback={<LinearProgress />}>
            <SelfRegisterPage />
          </Suspense>
        }
      />
      <Route
        path='self-register/:id/:receptionId'
        loader={loaderSelfRegister}
        element={<SelfRegisterLoaderContainer />}
      />
    </Route>
  )
);

export default JSXRoutes;
